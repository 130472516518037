/* magic spell below was copied from https://stackoverflow.com/a/70559598 */
/// <reference lib="webworker" />
export default null;
declare let self: ServiceWorkerGlobalScope;

const cacheName = "wiederholServiceWorkerCacheV1";

self.addEventListener("install", (event: ExtendableEvent) => {
  self.skipWaiting();

  event.waitUntil(
    (async function () {
      const cache = await caches.open(cacheName);
      await cache.addAll(["/"]);
    })(),
  );
});

self.addEventListener("activate", (event: ExtendableEvent) => {
  event.waitUntil(self.clients.claim());
});

const shouldCacheRequest = function (request: Request) {
  const requestPath = new URL(request.url).pathname;
  const rootPath = "/";
  const manifestPath = "/manifest.json";
  const assetUrlPattern = /\.(css|js|woff2|ico|png)$/;
  const checklistUrlPattern = /^\/[a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+$/;

  return (
    request.method === "GET" &&
    (requestPath === rootPath ||
      requestPath === manifestPath ||
      assetUrlPattern.test(requestPath) ||
      checklistUrlPattern.test(requestPath))
  );
};

self.addEventListener("fetch", (event: FetchEvent) => {
  if (!shouldCacheRequest(event.request)) {
    return;
  }

  event.respondWith(
    (async function () {
      const networkPromise = fetch(event.request);
      const cachePromise = caches.open(cacheName);

      try {
        const networkResponse = await networkPromise;
        cachePromise.then((cache) => {
          cache.put(event.request, networkResponse);
        });
        return networkResponse.clone();
      } catch (error) {
        const cache = await cachePromise;
        const cacheResponse = await cache.match(event.request);
        if (cacheResponse) {
          return cacheResponse;
        } else {
          throw error;
        }
      }
    })(),
  );
});
